<template>
<v-col cols="12" class="py-0 bg-brilliant">
    
  <v-row style="display:none">
    <v-img class="home-slide-vimg" :src="root_node.url + ':' + root_node.port + '/img/home/homeslide1.webp'"/>
    <v-img class="home-slide-vimg" :src="root_node.url + ':' + root_node.port + '/img/home/homeslide2.webp'"/>
    <v-img class="home-slide-vimg" :src="root_node.url + ':' + root_node.port + '/img/home/homeslide3.webp'"/>
    <v-img class="home-slide-vimg" :src="root_node.url + ':' + root_node.port + '/img/home/homeslide4.webp'"/>
    <v-img class="home-slide-vimg" :src="root_node.url + ':' + root_node.port + '/img/home/homeslide5.webp'"/>
    <v-img class="home-slide-vimg" :src="root_node.url + ':' + root_node.port + '/img/home/homeslide6.webp'"/>
  </v-row>

  <v-row style="overflow:hidden;">
    <template v-for="i in (1, 6)">
      <v-col cols="12" :key="i" :style="'opacity: '+(i == inxSlide ? '0.3' : '0' ) + '; background-image: url(\''+ root_node.url + ':' + root_node.port + '/img/home/homeslide' + i + '.webp\')'" 
            class="home-slide-img mt-0">
      </v-col>
    </template>
  </v-row>

  <v-row class="ma-0" style="">
    <v-col cols="12" sm="12" md="12" lg="12" class="mx-auto section pa-0 text-center" style="z-index:1;height:800px;">
        <h1 class="font-main main-title-header pb-1 pt-5" style="">

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip large color="red" class="beta" v-bind="attrs" v-on="on">
              <v-icon>mdi-shield-star</v-icon> DEMO
            </v-chip>
          </template>
          <span>Version démo, en cours de développement...</span>
        </v-tooltip>
          <br>
            <b>Òtolife</b> <span class="2042 orange--text">2042</span>
        </h1>
        <h3 class="pt-0" style="font-weight:200;line-height:22px;">
          Jeu de stratégie,
          <br class="d-sm-none">simulation économique
          <br class="d-sm-none">post-effondrement
        </h3>
        <h2 class="pt-4" style="font-size:30px;line-height:25px;font-weight:200;">
          Et toi, tu seras heureux,<span class="d-inline-block"> en 2042 ?</span>
        </h2>
        <v-btn height="60" style="font-size:22px !important;" to="/register"
              elevation="0" class="mt-5 font-3x font-main" color="green">
          <!-- <v-icon>mdi-gamepad-variant</v-icon> -->
          <v-icon>mdi-gesture-tap-button</v-icon>
          <b>Jouer maintenant !</b>
        </v-btn>
    </v-col>

  </v-row>


  <v-row class="mt-0 section" style="">
    <v-col cols="12" sm="12" md="7" lg="7" class=" text-left white--text mx-auto">
      <div class="s-main-title font-main">
        <span class="indigo--text text--lighten-4">
           Serez-vous capable de survivre à l'effondrement du système ?
        </span>
        <div class="s-main-text font-main pb-0">
            
        </div>
        <div class="s-main-text font-main pb-0">
            En 2042, les ressources énergétiques et les matières premières commencent à se faire rares... 
            Très rares... Tellement rares, que tout est devenu excessivement cher, 
            et qu'il a fallu révolutionner toute l'économie pour avoir une chance de survivre...
            <br><br>
            Grace à une nouvelle monnaie, et de nouvelles règles économiques, 
            on a commencé à reconstruire un monde plus durable et plus sain...
            <br><br>
            Mais pour y arriver, on va avoir besoin de tous les bras, et surtout de tous les cerveaux disponibles !
            <b>
            Alors ? Tu viens nous aider à changer le monde ?
            </b>
        </div>
        <div class="s-main-text text-right font-main pb-0">
            
            <v-btn large elevation="0" class="mt-5 font-main" color="green" to="/register">
              <!-- <v-icon>mdi-gamepad-variant</v-icon> -->
              <v-icon>mdi-gesture-tap-button</v-icon>
              <b>Créer mon personnage !</b>
            </v-btn>
        </div>
      </div>
    </v-col>
    <v-col cols="12" md="4" lg="4" class="text-center mx-auto s-image">
      <v-icon style="font-size:280px;margin-top:80px;" color="red">mdi-trending-down</v-icon>
      <!-- <v-img src="../assets/img/homepage/wallet3.png" max-height="450" contain class=""/> -->
    </v-col>
  </v-row>


  <v-row style="background-color:rgb(18,18,18);" class="mt-0 section">
    <v-col cols="12" md="4" lg="4" class="text-center mx-auto s-image">
      <v-icon style="font-size:120px;margin-bottom:-10px; margin-right:-150px;" color="yellow">mdi-thought-bubble-outline</v-icon><br>
      <v-icon style="font-size:260px;margin-top:0px;" color="green">mdi-head-dots-horizontal-outline</v-icon>
      <!-- <v-img src="../assets/img/homepage/wallet3.png" max-height="450" contain class=""/> -->
    </v-col>
    <v-col cols="12" sm="12" md="7" lg="7" class=" text-left white--text mx-auto">
      <div class="s-main-title font-main">
        <span class="indigo--text text--lighten-4">
           Jouez et participez à imaginer le monde de demain !
        </span>
        <div class="s-main-text font-main pb-0">
            
        </div>
        <div class="s-main-text font-main pb-0">
            Découvrez, par le jeu, comment la mise en place d'une <b>nouvelle monnaie révolutionnaire</b>
            peut relancer n'importe quelle économie, même à l'agonie !
            <br><br>
            En créant votre personnage virtuel, vous prendrez part à la construction de cette nouvelle économie,
            et tenterez de survivre et d'être heureux en comblant les différents besoins de votre perso...
        </div>
      </div>
    </v-col>
  </v-row>


  <v-row style="background-color:rgb(10,10,10); padding-bottom:70px;" class="mt-0 section">
    <v-col cols="12" sm="11" class=" text-left indigo--text text--lighten-4 mx-auto">
      <div class="s-main-title pb-0 pl-0 font-main">
        Pour être heureux,<br>on a tous les mêmes besoins...
      </div>
      <div class="s-main-subtitle pb-5 pt-3 pl-0 font-main">
        <i style="font-weight:300; font-size:0.85em;">Mais on prend pas les mêmes chemins...</i>
      </div>
      <div class="s-main-text font-main pb-5">
          Plus les besoins d'un joueur sont satisfaits, plus il est heureux.<br>
          Le but du jeu est de rendre votre personage le plus heureux possible,
          en essayant de répondre à toutes ses attentes.<br><br>
          Si vous y parvenez, vous atteindrez les sommets du Classement Général 
          <v-icon color="yellow">mdi-star</v-icon>
          <v-icon color="yellow">mdi-star</v-icon>
          <v-icon color="yellow">mdi-star</v-icon>
          <br>
          et vous obtiendrez une reconnaissance éternelle au tableau des joueurs les plus heureux au monde... <i>en 2042</i> !
      </div>
    </v-col>

    <v-col cols="12" sm="11" class="mx-auto">
      <v-row style="" class=" mt-0 section">
        <template v-for="(score, keyS) in scoreModels">
          <v-col cols="12" sm="6" md="4" lg="2" :key="keyS" class="text-center mx-auto pa-3 pa-lg-0">
            <v-card @mouseenter="randScores" class="px-3 py-5" min-height="220" color="black" style="border-radius:20px;">
              <v-icon x-large>mdi-{{ score.icon }}</v-icon><br>
              {{ score.name }}
              <v-progress-linear class="mt-2" 
                                :value="score.score" 
                                :color="score.score > 70 ? 'green' : (score.score > 30) ? 'orange' : 'red'">
              </v-progress-linear>
              <v-card-text class="pa-0 py-3"><small>{{ score.description }}</small></v-card-text>
            </v-card>
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>


  <v-row style="background-color:rgb(18,18,18);" class="mt-0 section">
    <v-col cols="12" md="4" lg="4" class="text-center mx-auto s-image">
      <v-icon style="font-size:160px;" color="teal">mdi-human-greeting-proximity</v-icon>
      <v-icon style="font-size:160px;" color="blue">mdi-lan-connect</v-icon>
      <!-- <v-img src="../assets/img/homepage/wallet3.png" max-height="450" contain class=""/> -->
    </v-col>
    <v-col cols="12" sm="12" md="7" lg="7" class=" text-left white--text mx-auto">
      <div class="s-main-title font-main">
        <span class="indigo--text text--lighten-4">
           Un jeu intéractif et coopératif
        </span>
        <div class="s-main-text font-main pb-0">
            
        </div>
        <div class="s-main-text font-main pb-0">
            Dans toute société humaine, nous avons besoin les uns des autres pour survivre.<br>
            C'est le rôle de l'économie : nous permettre d'échanger des biens et des services.<br><br>
            Votre personnage aura donc aussi besoin des autres joueurs pour survivre, 
            et devra intéragir avec eux pour améliorer ses conditions de vie...
        </div>
      </div>
    </v-col>
  </v-row>



  <v-row style="background-color:rgb(10,10,10);" class="mt-0 section">
    <v-col cols="12" sm="12" md="7" lg="7" class=" text-left white--text mx-auto">
      <div class="s-main-title font-main">
        <span class="indigo--text text--lighten-4">
           Travailler pour soi,<br>
           et pour les autres...
        </span>
        <div class="s-main-text font-main pb-0">
            
        </div>
        <div class="s-main-text font-main pb-0">
            Pour accéder aux biens et services dont il a besoin, chaque joueur doit faire en sorte d'avoir suffisament d'argent...
            Il doit donc en gagner !
            <br><br>
            Pour cela, il peut devenir le propriétaire de sa propre entreprise, ou bien choisir d'être salarié dans l'entreprise d'un autre joueur...
            <br><br>
            Chaque entreprise produit des biens et des services différents, qui permettent de répondre aux besoins de tous les joueurs...
            <br><br>
            <b>C'est donc la complémentarité entre les joueurs qui crée la richesse et la solidité de son économie...</b>

        </div>
        <div class="s-main-text text-right font-main pb-0">
        </div>
      </div>
    </v-col>
    <v-col cols="12" md="4" lg="4" class="text-center mx-auto s-image">
      <v-icon style="font-size:190px;" color="teal">mdi-account-arrow-left-outline</v-icon>
      <v-icon style="font-size:190px;" color="blue">mdi-account-arrow-right-outline</v-icon>
      <!-- <v-img src="../assets/img/homepage/wallet3.png" max-height="450" contain class=""/> -->
    </v-col>
  </v-row>


  <v-row style="background-color:rgb(18,18,18);" class="mt-0 section">
    <v-col cols="12" sm="12" class=" text-center white--text mx-auto">
      <div class="s-main-title pb-0 font-main">
        <span class="indigo--text text--lighten-4">
           Une véritable économie virtuelle<br>
           à développer collectivement...
        </span>
        <div class="s-main-text font-main pb-5">
            Tous les <b>produits et services</b> consommables dans le jeu doivent être produits par les joueurs eux-mêmes...<br>
            L'objectif est de reproduire une économie la plus complète possible, pour simuler l'activité économique la plus réaliste possible...

        </div>
      </div>
    </v-col>
    <v-col cols="12" sm="11" lg="8" class=" text-center white--text mx-auto">
      <template v-for="product in $store.state.app.catalog.products">
        <v-tooltip :key="product._id" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" large 
                    class="pa-2" :color="product.iconColor">
              mdi-{{ product.icon }}
            </v-icon>
          </template>
          <span>{{ product.name }}</span>
        </v-tooltip>
      </template>

      <div class="s-main-text my-5 font-main">
        Toutes les <b>entreprises</b> ont un rôle à jouer, pour produire, transformer, ou distribuer l'ensemble de ces produits et services...
      </div>
      <template v-for="company in $store.state.app.catalog.companies">
        <v-chip :key="company._id" outlined class="pa-3 ma-1">
          <v-icon class="mr-1" style="font-size:17px;" :color="company.category.iconColor">mdi-{{ company.category.icon }}</v-icon>
          <b>{{ company.name }}</b> 
        </v-chip>
      </template>

      <div class="s-main-text my-5 font-main">
        Différents types de <b>véhicules</b> peuvent être utilisés pour les déplacements et les livraisons de marchandises...
      </div>
      <v-row class="mt-0 ">
        <template v-for="(product) in $store.state.app.catalog.products">
          <v-col v-if="product.category=='vehicle'" :key="product._id" cols="6" sm="6"  md="3" class="mx-auto">
              <v-progress-circular :rotate="-90" :size="140" :width="10"
                :value="product.kmToday / product.kmByDay * 100" 
                :color="(product.kmToday / product.kmByDay * 100 < 50) ? 'green' : (product.kmToday / product.kmByDay * 100 < 70) ? 'orange' : 'red'"
                :title="'utilisation aujourd\'hui ' + product.kmToday / product.kmByDay * 100 + '%'"
              >
                <v-icon style="font-size:60px">mdi-{{ product.icon }}</v-icon>
              </v-progress-circular><br>
              <h3>{{ product.name }}</h3>
              <b>{{ product.kmByDay }} km/jour</b><br>
              <b>{{ product.weightCapacity }} kg/ chargement</b>
          </v-col>
        </template>
      </v-row>
      <br><br><br>
      <v-btn @click="$root.$emit('openDialogCatalog')" x-large>
        Afficher le catalogue complet
      </v-btn>
      <br><br><br>
    </v-col>
  </v-row>



  <v-row style="background-color:rgb(10,10,10);" class="mt-0 section">
    <v-col cols="12" sm="8" lg="6" class=" text-left white--text mx-auto">
      <div class="s-main-title pl-5 pb-0 font-main">
        <span class="indigo--text text--lighten-4">
            + Crée ton personnage, et commence à jouer en quelques <span class="d-inline-block">secondes !</span><br><br>
            <span class="green--text">100% gratuit !</span>
        </span>
        <div class="s-main-subtitle font-main pl-0 pb-5 indigo--text text--lighten-4">
            Le plus dur sera de 
            <span class="d-inline-block">trouver un pseudo</span><br>
            pour ton avatar du <span class="d-inline-block">futur !</span>
        </div>
      </div>
    </v-col>
    <v-col cols="12" sm="8" md="6" lg="4" class="mt-5 pt-5 text-center white--text mx-auto">
      <br><br>
      <Register></Register>
    </v-col>
  </v-row>

  <v-row>
    <v-col class="pa-0">
      <Footer v-if="showFooter"/>
    </v-col>
  </v-row>

  <OfferDialog></OfferDialog>
</v-col>

</template>

<style>
.home-slide-img{
  position:absolute;
  top:-50px;
  right: -10%;
  left:-10%;
  width:120% !important;
  max-width:120% !important;
  background-size: cover;
  background-position: center;
  min-height: 850px;
  z-index:0;
  transition:opacity 2s;
  animation: move 25s infinite;
}
.theme--dark.v-application{
  background-color:rgb(10,10,10);
}

.v-text-field > .v-input__control > .v-input__slot::before{
  border-width: 5px;
}

@keyframes move { 
  0% { 
    /* transform-origin: 100% 0;  */
    transform: perspective(800px) rotateY(0deg) rotateX(0deg); 
  }
  20% { 
    /* transform-origin: 100% 0;  */
    transform: perspective(800px) rotateY(-3deg) rotateX(-3deg); 
  }
  40% { 
    /* transform-origin: 0 100% ;  */
    transform: perspective(800px) rotateY(-3deg) rotateX(3deg); 
  }
  60% { 
    /* transform-origin: 100% 0;  */
    transform: perspective(800px) rotateY(3deg) rotateX(-3deg); 
  }
  80% { 
    /* transform-origin: 0 100% ;  */
    transform: perspective(800px) rotateY(3deg) rotateX(3deg); 
  }
  100% { 
    /* transform-origin: 0 100%;  */
    transform: perspective(800px) rotateY(0deg) rotateX(0deg); 
  }
}
</style>

<script>

import '@/assets/css/home.css';

import Footer from '@/components/main-layout/Footer.vue'
import OfferDialog from '@/components/offer/OfferDialog.vue'
import Register from '@/components/main-layout/Register.vue'


const config = require('../config/' + process.env.NODE_ENV)

export default {
  name: 'home',
  components: {
    Footer, 
    OfferDialog,
    Register
  },
  data: () => ({
    showTopInfoBar: true,
    showFooter: true,
    plans: [],
    inxSlide: 1,
    tabCatalog: 1,
    scoreModels: [],
    pseudo: ""
  }),
  mounted: async function(){
    this.startTimerSlide()
    this.$store.dispatch('app/incStat', '/home')
    this.$store.dispatch('app/fetchNbUsersTotal')
    this.$store.dispatch('app/fetchEntities', { entityType: 'plan', 
                                                query : { "isActive": true },
                                                sort: { position: 1 } 
                                              })
    this.scoreModels = await this.$store.dispatch('app/fetchEntities', 
                                                      { entityType: 'score', 
                                                        query : { "isModel": true },
                                                        sort: { inx: 1 } 
                                                      })
    //this.randScores()
  },
  methods: {
    closeTopInfoBar(){
      this.showTopInfoBar = false
    },
    startTimerSlide(){
      if(this.timer != null) clearInterval(this.timer)
      this.timer = setInterval(()=>{
        if(this.inxSlide >= 6) this.inxSlide = 0
        this.inxSlide++
      }, 4000)
    },
    randScores(){
      this.scoreModels = this.$store.state.app.data.score 
      this.scoreModels.forEach((model)=>{
        model.score = Math.random() * 100
      })
    }
  },
  computed: {
    root_node(){ return config.root_node },
    
  },
  watch: {
    '$store.state.app.data.score': { immediate: true, 
        async handler () {
            this.randScores()
        }
    },
  }
}
</script>
